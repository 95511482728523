import React from 'react'
import {BlockLayoutsObj, BlockPostStylesObj} from './../BlockDefinitions'
import {OptionsContext} from './../../contexts/OptionsContext'
import {BlockBuilderController} from './../../contexts/BlockBuilderController'
const EditBlockBtn = ({dataJSON, blockI, classes}) => {
    const {
        dispatchUserChoice__block,
        dispatchUserChoice__postStyle,
        dispatchPostsJSON
    } = React.useContext(OptionsContext)

    const {
        BlockOptionsFront__tabs,
        dispatchBlockOptionsFront__openedTab
    } = React.useContext(BlockBuilderController)

    const editBlock = () => {
        dispatchUserChoice__block({
            type: 'UPDATE',
            payload: {selectedBlock: blockI}
        })
        dispatchBlockOptionsFront__openedTab({
            type: 'UPDATE',
            payload: BlockOptionsFront__tabs.CREATE
        })
        dispatchPostsJSON({
            type: 'UPDATE',
            payload: dataJSON.postsJSON
        })
        console.log('dataJSON.postsJSON', dataJSON.postsJSON)
        /*console.log('block: ' + blockI);
        console.log(BlockLayoutsObj[dataJSON.blockLayout.name]);
        console.log(BlockPostStylesObj[dataJSON.postStyle[0].name]);*/
    }
    return (
        <button
            className={'btn btnB ' + (classes? classes:'')}
            onClick={editBlock}
        >
            edit block
        </button>
    )
}

export default EditBlockBtn