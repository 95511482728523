import React from 'react';
import {BlockBuilderController} from './../contexts/BlockBuilderController'
import Premade from './premade/Premade'
import BlockBuilder from './BlocksBuilder/BlockBuilder'

const MainContent = () => {
    const [loadedTabs, setLoadedTabs] = React.useState([]);
    const {
        BlockOptionsFront__tabs,
        BlockOptionsFront__openedTab,
        setBlockOptionsFront__openedTab,
        switchTabs
    } = React.useContext(BlockBuilderController);

    if(!loadedTabs[BlockOptionsFront__openedTab])
        loadedTabs[BlockOptionsFront__openedTab] = true;

    const classes = BlockOptionsFront__openedTab === BlockOptionsFront__tabs.CREATE? 'active__BlockBuilder' : 'active__Premade';
    return (
        <div className={'MainContent ' + classes}>
            {
                (loadedTabs[BlockOptionsFront__tabs.CREATE])? <BlockBuilder/> : ''
            }
            {
                (loadedTabs[BlockOptionsFront__tabs.PREMADE])? <Premade/> : ''
            }
        </div>
    )
}

export default MainContent;