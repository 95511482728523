import React from 'react'
import Logo from './Logo'
import Login from './Login'
import MianTabs from './MainTabs/MainTabs'

const Header = () => {

    return (
        <>
        <div className='Header'>

            <Logo />
            <MianTabs />
            <Login />
        
        </div>
        <div className='HeaderRep'></div>
        </>
    )
}

export default Header