

import {postTemps, layoutUnitWrapper} from './postsComponents';



function generateFeaturedBlock(postsJSON, dataJSON, classes = '') {
	classes =  extractClasses(dataJSON.blockLayout.classes) + classes;
	var posts = [];
	for(var i = 0; i < postsJSON.length; i++){
		
		if(dataJSON.blockLayout.editEachPostIndividually && dataJSON.postStyle.length > i){
			posts.push(postTemps[dataJSON.postStyle[i].name](postsJSON[i], '', dataJSON.postStyle[i]));
		}
		else{
			posts.push(postTemps[dataJSON.postStyle[0].name](postsJSON[i], '', dataJSON.postStyle[0]));
		}
	}
	var blockHTML = blockLayouts[dataJSON.blockLayout.name](posts, classes);	

	var isSlider = false;

	var props = {};
	
	if(dataJSON.blockLayout.name.indexOf('slider') > -1){
		isSlider = true;
		props = {
			dots: (dataJSON.blockLayout.classes.slider__dots == 'slider__dots_show'),
			centerMode: dataJSON.blockLayout.isCenterMode,
		};
	}

	var callBack = function(el){
		console.log('claaaal back');

		/*if(el){
			$(el).find('.slider').slick(props);
		}*/
	}
	blockHTML = `
	<div
		data-label=""
		class="featuredBlock featuredBlock_notLoaded"
		${isSlider? 'data-sliderProps="' + JSON.stringify(props).replace(/"/g, "$q$") +'"' : ''}
		data-blockName='${dataJSON.blockLayout.name}'
		data-numOfPosts="${dataJSON.blockLayout.numOfPosts}"
	>
		${blockHTML}
	</div>
	`;

	return blockHTML.replace(/(\n|\t| )+/g, ' ')

}


var blockLayouts = {
	fBlock1: function (posts, classes = '') {
		return (`
			<div class="${'fBlk1 d-flex flex-wrap ' + classes}">
				<div class='fBlk1__col1'>
					${layoutUnitWrapper(posts[0], 'fBlk1__1')}
				</div>
				<div class="fBlk1__col2 d-flex flex-wrap">
					${
						layoutUnitWrapper(posts[1], 'fBlk1__2 flex-grow-1')
					}
					${
						layoutUnitWrapper(posts[2], 'fBlk1__2 flex-grow-1')
					}
				</div>

			</div>
			`);
	},
	fBlock2: function (posts, classes = '') {
		return (`
			<div class="${'fBlk2 d-flex flex-wrap ' + classes}">
				${
					layoutUnitWrapper(posts[0], 'fBlk2__2') +
					layoutUnitWrapper(posts[1], 'fBlk2__2') +
					layoutUnitWrapper(posts[2], 'fBlk2__3') +
					layoutUnitWrapper(posts[3], 'fBlk2__3') +
					layoutUnitWrapper(posts[4], 'fBlk2__3')
				}
			</div>
			`);
	},
	fBlock3: function (posts, classes = '') {
		return (`
			<div class="${'fBlk3 d-flex flex-wrap ' + classes}">
				<div class='fBlk3__col1'>
					${layoutUnitWrapper(posts[0], 'fBlk3__1')}
				</div>
				<div class='fBlk3__col2 d-flex flex-wrap'>
					${
					
						layoutUnitWrapper(posts[1], 'fBlk3__4') +
						layoutUnitWrapper(posts[2], 'fBlk3__4') +
						layoutUnitWrapper(posts[3], 'fBlk3__4') +
						layoutUnitWrapper(posts[4], 'fBlk3__4')
					}
				</div>
				
			</div>
			`);
	},
	fBlock4: function (posts, classes = '') {
		return (`
			<div class="${'fBlk4 d-flex flex-wrap ' + classes}">
				<div class='fBlk4__col1 d-flex flex-wrap'>
					${layoutUnitWrapper(posts[1], 'fBlk4__2')}
					${layoutUnitWrapper(posts[3], 'fBlk4__2')}
				</div>
				<div class='fBlk4__col2 d-flex flex-wrap'>
					${layoutUnitWrapper(posts[0], 'fBlk4__1')}
				</div>

				<div class='fBlk4__col1 d-flex flex-wrap'>
					${layoutUnitWrapper(posts[2], 'fBlk4__2')}
					${layoutUnitWrapper(posts[4], 'fBlk4__2')}
				</div>
				
			</div>
			`);
	},
	block1: function (posts, classes = '') {
		return postsGrid(posts, 'grid__1 layout__1 ' + classes)
	},
	block2: function (posts, classes = '') {
		return postsGrid(posts, 'grid__2 layout__2 ' + classes)
	},
	block3: function (posts, classes = ''){
		return postsGrid(posts, 'grid__3 layout__3 ' + classes)
	},
	block4: function (posts, classes = ''){
		return postsGrid(posts, 'grid__4 layout__4 ' + classes)
	},
	block5: function (posts, classes = ''){
		return postsGrid(posts, 'grid__5 layout__5 ' + classes)
	},
	block6: function (posts, classes = ''){
		return postsGrid(posts, 'grid__6 layout__6 ' + classes)
	},

	slider1: function (posts, classes = '') {
		return slider(posts, 'slider__1 grid__1 ' + classes)
	},
	slider2: function (posts, classes = '') {
		return slider(posts, 'slider__2 grid__2 ' + classes)
	},
	slider3: function (posts, classes = '') {
		return slider(posts, 'slider__3 grid__3 ' + classes)
	},
	slider4: function (posts, classes = '') {
		return slider(posts, 'slider__4 grid__4 ' + classes)
	},
	slider5: function (posts, classes = '') {
		return slider(posts, 'slider__5 grid__5 ' + classes)
	},
	slider6: function (posts, classes = '') {
		return slider(posts, 'slider__6 grid__6 ' + classes)
	}


}

function postsGrid(posts, classes = ''){
	for(var i = 0; i < posts.length; i++){
		posts[i] = layoutUnitWrapper(posts[i]);
	}

	return (`
			<div class="${'d-flex flex-wrap ' + classes}" >
				${
					posts.join("")
				}
			</div>
	`);
}
function postsCollection(posts, classes = ''){
	for(var i = 0; i < posts.length; i++){
		posts[i] = layoutUnitWrapper(posts[i]);
	}
	return (`
			<div class="${'postGrid '+classes}" >
				${
					posts.join("")
				}
			</div>
	`);
}

function slider(posts, classes = ''){
	return postsCollection(posts, 'slider ' + classes);
}

function extractClasses(classesObj) {
	var classes = '';
	for(var prop in classesObj){
		if(classesObj[prop])
			classes = classes + classesObj[prop] + ' ';
	}

	return classes;
}

function objToAttr(obj) {
	var attr = '';
	for(var key in obj){
		attr += key + '="' + obj[key] +'" '
	}
	return attr;
}
export default generateFeaturedBlock;