import React, { Component, createContext, useReducer } from 'react'
export const BlockBuilderController = createContext();


const BlockOptionsFront__openedTabRed = (state, action) => {
    switch(action.type){
        case 'UPDATE':
            return action.payload

        default:
            return state;
    }
}
const GetCodeModal__isOpenedRed = (state, action) => {
    switch(action.type){
        case 'UPDATE':
            return action.payload

        default:
            return state;
    }
}

const BlockBuilderControllerProvider = (props) => {
    const [BlockOptionsFront__tabs] = React.useState({
        CREATE: '0',
        PREMADE: '1'
    });

    
    const [BlockOptionsFront__openedTab, dispatchBlockOptionsFront__openedTab] =
        useReducer(
            BlockOptionsFront__openedTabRed,
            BlockOptionsFront__tabs.PREMADE
        );

    const [GetCodeModal__isOpened, dispatchGetCodeModal__isOpened] =
        useReducer(
            GetCodeModal__isOpenedRed,
            false
        );



    const switchTabs = (e) => {
        dispatchBlockOptionsFront__openedTab(
            {
                type:'UPDATE',
                payload: e.currentTarget.getAttribute('tab') 
            }
        );
        
            
    }
    const [currentBlockCode, setCurrentBlockCode] = React.useState();

    

    const openCodeModal = () => {
        dispatchGetCodeModal__isOpened({type: 'UPDATE', payload: true});
    }
    return (
        <BlockBuilderController.Provider

            value={
                {
                    BlockOptionsFront__tabs,
                    BlockOptionsFront__openedTab,
                    dispatchBlockOptionsFront__openedTab,
                    switchTabs,
                    GetCodeModal__isOpened,
                    dispatchGetCodeModal__isOpened,
                    openCodeModal,
                    currentBlockCode,
                    setCurrentBlockCode
                }
            }
        
        >
            {props.children}
        </BlockBuilderController.Provider>

    )
}

export default BlockBuilderControllerProvider