import React from 'react'


const Login = () => {

    return (
        <div className='Login'>

            
        </div>
    )
}

export default Login