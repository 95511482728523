import React, {useState, useEffect} from 'react'
import {savePropFromArr, extractValue} from './../../../../../utils/func'
import OptionsList from './OptionsList'
import ReactSVG from 'react-svg'
const IMG_DIR = '/block-builder/img/';
const SelectWithImage = ({items, name, obj, whereToSave, $i, dispatch}) => {

    const [optionsListIsOpen, setOptionsListIsOpen] = useState(false);
    const selectedOptionI = extractValue(whereToSave, obj, $i);

    const passSelected = (i) => {
        dispatch({type: 'UPDATE', payload: savePropFromArr(whereToSave, obj, i, $i)});
    }
    const option = items[selectedOptionI];
    const imgSRC = IMG_DIR + option.type + '/' + option.specs.name + '.svg';
    const renderImg = () => {
        return(
            <ReactSVG src={imgSRC} />
        )
    }
    const openCloseOptions = () => {
        setOptionsListIsOpen(!optionsListIsOpen)
    }
    const selectWithImg = React.useRef();
    
    useEffect(() => {
        // add when mounted
        document.addEventListener("mousedown", handleClickOut);
        // return function to be called when unmounted
        return () => {
          document.removeEventListener("mousedown", handleClickOut);
        };
    }, []);
    const closeOptionsList = () => {
        setOptionsListIsOpen(false);
    }
    const handleClickOut = (e) => {
        if(!(selectWithImg.current.contains(e.target))){
            closeOptionsList();
        }
    }

    return (
        <div ref={selectWithImg} className='selectWithImage'>
            
            <div className='selectWithImg__selectedOption' onClick={openCloseOptions}>
                {renderImg()}
            </div>

            <button className='selectWithImg__chooseBtn btn btnA w100' onClick={openCloseOptions}>choose</button>
            {
                optionsListIsOpen?
                    <OptionsList
                        options={items}
                        selectedOptionI={selectedOptionI}
                        passSelected={passSelected}
                    /> : ''
            }

        </div>
    )
}

export default SelectWithImage