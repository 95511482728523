import React from 'react'
import ReactSVG from 'react-svg'
const Icon = ({src, className}) => {
    
    
    return(
        <ReactSVG
            src={src}
            className={className? className : ''}
        />
    )
}

export default Icon;