import React from 'react'
import {savePropFromArr} from './../../../../utils/func'
const Classes = ({info, obj, dispatch, value}) => {
    const {choices} = info;
    const items = [];
    const onChange = (e) => {
        dispatch({type: 'UPDATE', payload: savePropFromArr(info.whereToSave, obj, e.target.value)});
    }
    
    for(var i = 0; i < choices.length; i++){
        if(!value)
            value = choices[0].class;

        var isChecked = value===choices[i].class;
        items.push(<div
                className='classesOption'
                key={choices[i].class}>
                <label className='classesOption__radioLabel'>
                    <input
                        className='classesOption__radioInput'
                        type="radio"
                        onChange={onChange}
                        name={info.name}
                        value={choices[i].class}
                        checked={isChecked}
                    />
                        <div className='classesOption__radioCheck'></div>
                        <span className='classesOption__txt'>
                            {choices[i].text}
                        </span>
                        
                </label>
            </div>);
    }
    
    return (
        <div className='classesOptions blockOptions__option'>
            <div className='optionTitle'>
                {info.text}
            </div>
            {items}
        </div>
    )
}

export default Classes