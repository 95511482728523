 exports.savePropFromArr = function(whereToSave, obj, value, $i = 0){
    
    var obj2 = obj;
    for(var i = 0; i < whereToSave.length - 1; i++){
        if(whereToSave[i] === '$i')
            obj2 = obj2[$i];
        else
            obj2 = obj2[whereToSave[i]];
    }
    if(whereToSave[whereToSave.length - 1] === '$i')
        obj2[$i] = value;
    else
        obj2[whereToSave[whereToSave.length - 1]] = value;
    return obj2;
}

exports.extractValue = function(loc, obj, $i){

    var value = obj;
    for(var i = 0; i < loc.length; i++){
        if(loc[i] === '$i')
            value = value[$i];
        else
            value = value[loc[i]];
        
    }
    return value;
}

exports.extractSpecs = (options__blockLayout, options__postStyle) => {
    var blockLayout = options__blockLayout.blockLayout.specs;
    var postStyle = [];
    var allPostStyle = options__postStyle.postStyle;

    var postStyleCount = 1;

    if(blockLayout.editEachPostIndividually)
        postStyleCount = allPostStyle.length;
    for(var i = 0; i < postStyleCount; i++){
        postStyle.push(allPostStyle[i].specs);
    }

    return {
        widName :"$featuredBlock",
        blockLayout: blockLayout,
        postStyle: postStyle,
    }
}
var resClassesInfo =
	[
		{size: 991.98, class: 'resCont-md'},
		{size: 767.98, class: 'resCont-sm'},
        {size: 575.98, class: 'resCont-xs'}
    ];
    
var resFBpostsClassesInfo =
	[
		{size: 1199.98, class: 'resPost-lg'},
		{size: 991.98, class: 'resPost-md'},
		{size: 767.98, class: 'resPost-sm'},
		{size: 575.98, class: 'resPost-xs'},
		{size: 400, class: 'resPost-400'},
        {size: 250, class: 'resPost-250'},
    ];
exports.resFeaturedBlocks = (FB) => {
    if (!FB.current) return;
    var className = ""
    var blockWidth = FB.current.offsetWidth;
    //console.log('blockWidth ' + blockWidth)
    
    for(var i = resClassesInfo.length - 1; i >= 0; i--){
        if(blockWidth <= resClassesInfo[i].size){
            //className += resClassesInfo[i].class + ' ';
            FB.current.classList.add(resClassesInfo[i].class);
        }else{
            FB.current.classList.remove(resClassesInfo[i].class);
        }
    }
    //FB.current.classList.add
    calcClasses(FB.current.querySelectorAll('.aPost'), resFBpostsClassesInfo);
    return className;

}

const calcClasses = (els, classesInfo) => {
    
    for(let i = 0; i < els.length; i++){
        var elWidth = els[i].offsetWidth;

        for(let j = classesInfo.length - 1; j >= 0; j-- ){
            if(elWidth <= classesInfo[j].size){
                els[i].classList.add(classesInfo[j].class);
            }else{
                els[i].classList.remove(classesInfo[j].class);
            }
        }
    }
}
exports.extractValueOfAttrib = (str, attrib) => {
    const attribDec = attrib + '=".+?"';

    var matchedAttrip = str.match(attribDec);

    if(!matchedAttrip) return false;

    matchedAttrip = matchedAttrip[0];

    const reg2 = RegExp('(' + attrib + '=\"|\"$)' ,"g");

    matchedAttrip = matchedAttrip.replace(reg2, '');

    return matchedAttrip;
    
}
exports.removeDIVwrapper = (blockHTML) => {
    blockHTML = blockHTML.replace(/\<div [^\>]*\>/, '');
    blockHTML = blockHTML.replace(/\<\/div\>([^\<\/div\>]*)$/, '');

    return blockHTML;
}
exports.getNPosts = (arr, n) => {
    //console.log('calc');
    var arrOfPosts = [];
    for(var i = 0; i < n; i++){
        var crntPost = JSON.parse(JSON.stringify(arr[i%(arr.length)]));
        crntPost.index = i;
        arrOfPosts.push(crntPost);
    }
    return arrOfPosts;
}

exports.minifyBlockCode = (blockCode) => {
    blockCode = blockCode.replace(/(\n|\t| )+/g, ' ');
    //blockCode = blockCode.replace(/ [ ]+/g, ' ');

    return blockCode;
}