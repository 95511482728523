import React from 'react'


const Logo = () => {

    return (
        <div className='Logo'>

            
        </div>
    )
}

export default Logo