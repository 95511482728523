
function resBGimg(cssClass) {
	if(!cssClass)
		cssClass = "resBGimg";

	if(typeof document === 'undefined') return;
	var BGimages = document.querySelectorAll("."+cssClass);

	if(!BGimages[0]) return;
	BGimages.forEach(function (el) {
		var isIMG = false;
		if(el.nodeName.toUpperCase() == ("IMG")){
				isIMG = true;
		}
		var imgURL = bloggerImageResize(el.getAttribute("data-img"), isIMG? el.parentElement.offsetWidth : el.offsetWidth, isIMG? el.parentElement.offsetHeight : el.offsetHeight);

		if(isIMG){
			el.setAttribute("data-src", imgURL);
		}
		else{
			el.setAttribute('style', 'background-image: url("' + imgURL + '")');
		}
		
		el.classList.remove(cssClass);
		el.classList.add('lazyload');

		if(!(imgURL.match(/w[0-9]+/g) || imgURL.match(/s[0-9]+/g))){
			objectFitSupport(el);
		}
	});

}
function bloggerImageResize(imgURL, imgW, imgH) {
	var allFlagsPattern = '[a-z]{1,2}[0-9]*';
	var sizeFlagPattern = '(w|h|s)[0-9]+';
	var fullPattern = `(${allFlagsPattern}-)*${sizeFlagPattern}(-${allFlagsPattern})*`;

	var imgIsResized = false;
	
	var newImgUrl = imgURL.replace(new RegExp('\/' + fullPattern + '\/'), function(str){
		imgIsResized = true;
		return '/' + getResizeStr(imgW, imgH) + '/';
	});

	if(imgIsResized) return newImgUrl;

	newImgUrl = imgURL.replace(new RegExp('=' + fullPattern), function(str){
		imgIsResized = true;
		return '=' + getResizeStr(imgW, imgH);
	});

	if(imgIsResized) return newImgUrl;


	if (imgURL.match(/youtube/g)) {
		return imgURL.replace("default.jpg", "sddefault.jpg");
	}
	else
		return imgURL;

}
function getResizeStr(w, h){
	return 'w' + Math.ceil(w) + '-h' + Math.ceil(h) + '-p';
}
 

resBGimg();

function objectFitSupport (el) {
	if(!("objectFit" in document.body.style))
	{
		var elParent = el.parentElement;
		var ParentRatio = elParent.offsetWidth / elParent.offsetHeight;
		var imgRatio = el.offsetWidth / el.offsetHeight;

		if(imgRatio > ParentRatio){
			el.classList.add('objectFitH');
		}
	}
}
if (typeof exports !== 'undefined') {
	module.exports = resBGimg;
}



//export default resBGimg
