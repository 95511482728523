import React from 'react'
import BlockOptions from './BlockOptions'
import MinimizeBtn from './utils/minimize/MinimizeBtn'
import {OptionsContext} from '../../contexts/OptionsContext'

const BlockOptionsFront = () => {

    const {
        userChoice__block,
        dispatchUserChoice__block
    } = React.useContext(OptionsContext);
    const [isMinimized, setIsMinimized] = React.useState(false);
    const [windowPos, setWindowPos] = React.useState({x: 5, y:5});





    const toggleMinimize = () => {
        setIsMinimized(!isMinimized);
    }
    
    return(
        <div 
            className={'blockOptionsWrapper ' + (isMinimized? 'BlockOptions__minimized': '')}
        >
            
            <div
                className='BlockOptions__drag'
            >
                <div className='BlockOptionsHeaderTitle'>
                    Block Options
                </div>
                <MinimizeBtn isMinimized={isMinimized} toggleMinimize={toggleMinimize} />
            </div>
            
            <div
                className='BlockOptionsContainer BlockOptions__scroll'>
                <BlockOptions />
                
            </div>
        </div>
    )
}

export default BlockOptionsFront;