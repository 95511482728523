import React, {useContext, useEffect, useState, useMemo} from 'react'
import generateFeaturedBlock from './../generateFeaturedBlock'
import subPosts from '../../../../src/HTML/assets/js/subPosts'
import posts from './../posts'
import {getNPosts} from '../../utils/func'
import PostSchema from './../PostSchema'
import BlockToReact from './BlockToReact'
import ViewAll from './ViewAll'
const BlockPreview =  ({dataJSON, setMaxWidth, getBlockTemp}) => {
    const postsJSON = dataJSON.postsJSON
    
    const postsJSONsrc = postsJSON && postsJSON.length? postsJSON : posts;
    const numOfPosts = dataJSON.blockLayout.numOfPosts;

    const postsSc = useMemo(() => getNPosts(PostSchema, numOfPosts), [PostSchema, numOfPosts]);
    const blockPosts = useMemo(() => getNPosts(postsJSONsrc ,numOfPosts), [postsJSONsrc ,numOfPosts]);
    const blockTemplate = useMemo(() => generateFeaturedBlock(postsSc, dataJSON, ''), [postsSc, dataJSON]);

    const blockHTML = useMemo(() => subPosts(blockTemplate, blockPosts), [blockPosts, dataJSON]);

    const blockReact = useMemo(
        () => <BlockToReact blockHTML={blockHTML} dataJSON={dataJSON}  />,
        [blockHTML, dataJSON]
    );
    if(getBlockTemp) getBlockTemp(blockTemplate);
    /*
    const postsSc = getNPosts(PostSchema, numOfPosts);
    const blockPosts = getNPosts(posts ,numOfPosts);
    const blockTemplate = generateFeaturedBlock(postsSc, dataJSON, '');

    const blockHTML = subPosts(blockTemplate, blockPosts);

    const blockReact = <BlockToReact blockHTML={blockHTML} dataJSON={dataJSON}  />;
    */
    const maxWidth = {maxWidth: dataJSON.blockLayout.maxWidth? dataJSON.blockLayout.maxWidth + 'px' : '900px'};
    
    const handleClick = (e) => {
        e.preventDefault();
    }
    const viewAll = dataJSON.blockLayout.classes.viewAll
    return (
        <div className='blockPreview'
            style={maxWidth}
            onClick={handleClick}
        >
                <div className='blockFrame'>
                    <div className='blockFrame__topBar'></div>
                
                <h4 className="widget__title blockTitle">
                    <span className="blockTitle__txt">
                        block title
                    </span>
                    {
                        (viewAll === 'viewAll__top')? <ViewAll classes='viewAll__top' /> : ''
                    }
                    
                </h4>
                {blockReact}
                {
                    (viewAll === 'viewAll__bottom')? <ViewAll classes='viewAll__bottom d-flex flex-row-reverse' /> : ''
                }
                {
                    (viewAll === 'viewAll__bottomCenter')? <ViewAll classes='viewAll__bottomCenter d-flex justify-content-center' /> : ''
                }
                </div>
                
                
        </div>
    )

}

export default React.memo(BlockPreview)