const PostSchema = [{
    title: "String",
	"link": "String",
	"img": "String",
	"date": "String",
	"commentsNum": "Int",
	"label": "String",
	"summary": "String",
	"reactions": ["String", "String"],
	"author": {
		"name": "String",
		"url": "String"
	},
	index: "Int"
}];

export default PostSchema;