import React from 'react'

import GetCodeModal from './getCode/GetCodeModal'
import Header from './Header/Header'

import MainContent from './MainContent'


import './../css/all.scss'


const All = () => {
    
    return (
        <>
            <Header />
            <MainContent />

            <GetCodeModal />
        </>
    )

    
}

export default All