import React from "react"
import All from '../components/All'
import OptionsContextProvider from '../contexts/OptionsContext'
import BlockBuilderControllerProvider from '../contexts/BlockBuilderController'
import { Helmet } from "react-helmet"
export default () => {
    function noop() {}
    if (process.env.NODE_ENV !== 'development') {
        console.log = noop;
        console.warn = noop;
    }
    return(
        <>
            <Helmet>
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta http-equiv="X-UA-Compatible" content="ie=edge" />
                <link rel="shortcut icon" type="image/png" href="favicon.png" />
                <title>Block Builder</title>
            </Helmet>
            <BlockBuilderControllerProvider>
                <OptionsContextProvider>
                    <All />
                </OptionsContextProvider>
            </BlockBuilderControllerProvider>
        </>
    )
}
