import React from 'react'
import ReactSVG from 'react-svg'
const IMG_DIR = '/block-builder/img/';
const Option = ({option, i, passSelected, isSelected}) => {

    const onClick = () => {
        passSelected(i);
    }
    const imgSRC = IMG_DIR + option.type + '/' + option.specs.name + '.svg';
    const renderImg = () => {
        return(
            <ReactSVG src={imgSRC} />
        )
    }
    return (
        <div onClick={onClick} className={'selectWithImage__option' + (isSelected? ' selected' : '')}>
            {renderImg()}
        </div>
    )
}

export default Option