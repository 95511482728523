import React from 'react'
import BlockOptionsFront from './BlockOptionsFront'
import BlockCrntPreview from './BlockCrntPreview'
const BlockBuilder = () => {

    return (
        <div className='BlockBuilder'>
            <BlockOptionsFront />
            <BlockCrntPreview />
        </div>
    )
}

export default BlockBuilder