var postTemps = {
  postWithImgBG: function(postJSON, classes = "", options) {
    classes = extractClasses(options.classes) + classes
    return `

			<div class='${classes + " aPost postWithImgBG imgBG"}'>
				<div class='thumbWrapper'>
					${postReactions(postJSON, "", options)}
					${postThumb(postJSON, "fullWH")}
				</div>
				<div class='postWithImgBG__content'>
					${postLabel(postJSON, "", options)}
					${postTitle(postJSON, "postWithImgBG__title")}
					${postData(postJSON, "", options)}
					${postSummary(postJSON, "", options)}
				</div>
			</div>
		`
  },
  postCard: function(postJSON, classes = "", options) {
    classes = extractClasses(options.classes) + classes

    return `
			<div class='${"postCard aPost " + classes}'>
				<div class='thumbWrapper'>
					${postReactions(postJSON, "", options)}
					${postThumb(postJSON, "postCard__postThumb")}
				</div>
				${postLabel(postJSON, "postCard__label", options)}
				${postTitle(postJSON, "postCard__title")}
				${postData(postJSON, "", options)}
				${postSummary(postJSON, "", options)}

			</div>
			`
  },
  postHCard: function(postJSON, classes = "", options) {
    classes = extractClasses(options.classes) + classes
    return `
			<div class='${"postHCard aPost " + classes}'>
				<div class="row">
					<div class="col-md-5">
						<div class='thumbWrapper'>
							${postReactions(postJSON, "", options)}
							${postThumb(postJSON, "postHCard__postThumb")}
						</div>
					</div>
					<div class="col">
						${postLabel(postJSON, "postCard__label", options)}
						${postTitle(postJSON, "postCard__title")}
						${postData(postJSON, "", options)}
						${postSummary(postJSON, "", options)}
					</div>
				</div>
			</div>
			`
  },
}

function postThumb(postJSON, classes = "") {
  var postImg = ""
  if (postJSON.img)
    postImg = `<!--rr<img
			alt='post photo'
			data-img='{{posts[${postJSON.index}].img}}'
			class='postThumb__img fullWH $r$resBGimg'
			src='{{posts[${postJSON.index}].img}}'
		/>rr-->`

  return `
		<a href='/' data-href='{{posts[${postJSON.index}].link}}' class='postThumb overlayN ${classes}'>
			${postImg}
		</a>

	`
}
function postTitle(postJSON, classes = "") {
  return `
		<h3 class='${"postTitle " + classes}'>
			<a href='/' data-href='{{posts[${postJSON.index}].link}}' class='postTitle__link'>
				{{posts[${postJSON.index}].title}}
			</a>
		</h3>
		`
}

function postSummary(postJSON, classes = "", options) {
  if (!options.showPostSummary) return ""
  return `
		<p class='${"postSnippet " + classes}'>
			{{posts[${postJSON.index}].summary}}
		</p>
		`
}

function postData(postJSON, classes = "", options) {
  if (
    !options.postData.showPostAuthor &&
    !options.postData.showPostTime &&
    !options.postData.showPostComments
  ) {
    return ""
  }

  var __postAuthor = ""
  var __postTime = ""
  var __postComments = ""

  if (options.postData.showPostAuthor)
    __postAuthor = `
			<a class='data data__auther data__part' href='/' data-href='${postJSON.author.url}'>
				{{posts[${postJSON.index}].author.name}}
			</a>
		`

  if (options.postData.showPostTime)
    __postTime = `
			<span class='data__time data__part'>
				{{posts[${postJSON.index}].date}}
			</span>
		`

  if (options.postData.showPostComments)
    __postComments = `
			<span class='data__comments data__part'>
				{{posts[${postJSON.index}].commentsNum}} {{messages_comments}}
			</span>
		`

  return `
			<div class='${"data postData " + classes}'>
				${__postAuthor}
				${__postTime}
				${__postComments}
			</div>
		`
}

function postLabel(postJSON, classes = "", options) {
  if (!options.showPostLabel || !postJSON.label) return ""

  return `
		<div class='${"postLabelCont " + classes}' >
			<a class='postLabel' href='/' data-href='/search/label/{{posts[${
        postJSON.index
      }].label}}'>
				{{posts[${postJSON.index}].label}}
			</a>
		</div>
		`
}

function postReactions(postJSON, classes, options) {
  if (!options.showPostReactions || !postJSON.reactions) return ""
  var reactions = postJSON.reactions
  var reactionsHTML = []
  for (var i = 0; i < reactions.length; i++) {
    reactionsHTML.push(`
			<li class="reactions__element">
				<a class="reactions{{posts[${postJSON.index}].reactions[${i}]}} reactions__link" href='/' data-href="/search/label/{{posts[${postJSON.index}].reactions[${i}]}}">
				</a>
			</li>
		`)
  }
  return `
		<ul class="reactions thumbReactions rmListStyle d-flex justify-content-center reactions_${
      postJSON.index
    }">
			${reactionsHTML.join("")}
		</ul>
	`
}

function layoutUnitWrapper(postHTML, classes = "") {
  return `
		<div class='${"layout__unit " + classes}'>
			${postHTML}
		</div> 
	`
}

function extractClasses(classesObj) {
  var classes = ""
  for (var prop in classesObj) {
    if (classesObj[prop]) classes = classes + classesObj[prop] + " "
  }

  return classes
}

exports.postTemps = postTemps
exports.layoutUnitWrapper = layoutUnitWrapper
