import React from 'react'
import {OptionsContext} from './../../contexts/OptionsContext';
import {savePropFromArr} from './../..//utils/func'
import Icon from './../global/Icon'
const WidthResizer = ({value, setValue}) => {
    //const [value, setValue] = React.useState(30);
    const {
        options__blockLayout,
        dispatchOptions__blockLayout,
    } = React.useContext(OptionsContext);
    const [isOpen, setIsOpen] = React.useState(false);
    const onChange = (e) => {
        //console.log('slider', e.target);
        setValue(e.target.value);
        dispatchOptions__blockLayout({type: 'UPDATE', payload: savePropFromArr(['blockLayout', 'specs', 'maxWidth'], options__blockLayout, e.target.value)})
    }
    const handleClick = () => {
        setIsOpen(!isOpen);
    }
    return (
        <>
        
        <div className={'WidthResizer' + (isOpen? ' opened' : '')}>
            <div className='WidthResizerIcon' onClick={handleClick}>
                    <Icon className='WidthResizerIcon__svg closeSVG' src='/block-builder/icons/close.svg' />
                    <Icon className='WidthResizerIcon__svg resizeSVG' src='/block-builder/icons/resize.svg' />
            </div>
            <div className='WidthResizer__form'>
                <p className='WidthResizer__title'>
                    Change the block preview width
                </p>
                <p className='WidthResizer__width'>
                    Current Width: <span className='WidthResizer__widthTxt'>{value}px</span>
                </p>
                <input
                    className='WidthResizer__input'
                    type="range"
                    min='300'
                    max='2000'
                    value={value}
                    onChange={onChange}
                />
            </div>
        </div>
        </>
    );
}

export default WidthResizer