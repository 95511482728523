import React, { Component, createContext, useState, useReducer, useEffect } from 'react'
import {BlockLayouts, BlockPostStyles, Blocks} from './../components/BlockDefinitions'
export const OptionsContext = createContext();



const userChoice__blockLayoutRed = (state, action) => {
    switch(action.type){
        case 'UPDATE':
            return {...state, ...action.payload}

        default:
            return state;
    }
}
const userChoice__postStyleRed = (state, action) => {
    switch(action.type){
        case 'UPDATE':
            return {...state, ...action.payload}

        default:
            return state;
    }
}

const options__blockLayoutRed = (state, action) => {
    switch(action.type){
        case 'UPDATE':
            return {...state, ...action.payload}

        default:
            return state;
    }
}

const options__postStyleRed = (state, action) => {
    switch(action.type){
        case 'UPDATE':
            return {...state, ...action.payload}

        default:
            return state;
    }
}

const userChoice__blockRed = (state, action) => {
    switch(action.type){
        case 'UPDATE':
            return {...state, ...action.payload}

        default:
            return state;
    }
}

const postsJSONRed = (state, action) => {
    switch(action.type){
        case 'UPDATE':
            return action.payload

        default:
            return state;
    }
}
const OptionsContextProvider = (props) => {

    const [options__blockLayout, dispatchOptions__blockLayout] = useReducer(options__blockLayoutRed, BlockLayouts[0]
    );

    const [options__postStyle, dispatchOptions__postStyle] = useReducer(options__postStyleRed, [BlockPostStyles[0]]
    );


    const [userChoice__blockLayout, dispatchUserChoice__blockLayout] = useReducer(userChoice__blockLayoutRed, {
        selectedBlockLayout: 0
    });
    
    const [userChoice__postStyle, dispatchUserChoice__postStyle] = useReducer(userChoice__postStyleRed, {
        selectedPostStyle: [0, 0, 0],
    });

    const [userChoice__block, dispatchUserChoice__block] = useReducer(userChoice__blockRed, {
        selectedBlock: -1,
    });
    const [postsJSON, dispatchPostsJSON] = useReducer(postsJSONRed, []);




    
    //console.log('options', options__blockLayout);
    //console.log('options__postStyle', options__postStyle);
    //console.log('userChoice__blockLayout', userChoice__blockLayout);
    //console.log('userChoice__postStyle', userChoice__postStyle);
    

    const updateOptions__blockLayout = () => {
        var blockLayout = JSON.parse(JSON.stringify(BlockLayouts[userChoice__blockLayout.selectedBlockLayout]));
    
        //to be reconsidered
        var numOfPosts = BlockLayouts[userChoice__blockLayout.selectedBlockLayout].specs.numOfPosts;

        if(userChoice__block.selectedBlock > -1){
            const block = Blocks[userChoice__block.selectedBlock];
            blockLayout = createCopyWithChanges(blockLayout, block.blockLayout);
        }
        console.log('blockLayout', JSON.stringify(blockLayout))
        dispatchOptions__blockLayout(
            {
                type: 'UPDATE', 
                payload: {
                    blockLayout: blockLayout,
                    numOfPosts: numOfPosts
                }
            }
        )
    }

    const updateOptions__postStyle = () => {
            var postStyle = [];
            var numOfPosts = options__blockLayout.specs.numOfPosts;
            for(var i = 0; i < userChoice__postStyle.selectedPostStyle.length; i++){
                postStyle.push(JSON.parse(JSON.stringify(BlockPostStyles[userChoice__postStyle.selectedPostStyle[i]])))
            }
            if(userChoice__block.selectedBlock > -1){
                
                const block = Blocks[userChoice__block.selectedBlock];
                
                for(let i = 0; i < block.postStyle.length; i++){
                    postStyle[i] = createCopyWithChanges(postStyle[i], block.postStyle[i]);
                }
                dispatchUserChoice__block({type:'UPDATE', payload: {selectedBlock: -1}})
            }
            
            dispatchOptions__postStyle({type: 'UPDATE', payload: {postStyle: postStyle}})
    }

    useEffect(() => {
        updateOptions__blockLayout();
        updateOptions__postStyle();
    }, []);

    useEffect(() => {
        updateOptions__blockLayout();

        if(userChoice__block.selectedBlock < 0){
            var numOfPosts = BlockLayouts[userChoice__blockLayout.selectedBlockLayout].specs.numOfPosts;
            dispatchUserChoice__postStyle(
                {
                    type: 'UPDATE',
                    payload: {
                        selectedPostStyle: new Array(numOfPosts).fill(0),
                    }
                }
            );
        }
        //updateOptions__postStyle();
    }, [userChoice__blockLayout]);

    useEffect(() => {
        updateOptions__postStyle();
    }, [userChoice__postStyle]);


    useEffect(() => {
        //console.log(Blocks[userChoice__block.selectedBlock]);
        //console.log(JSON.stringify(Blocks));
        //console.log(Blocks);
        if(userChoice__block.selectedBlock >= 0){

            dispatchUserChoice__postStyle(
                {
                    type: 'UPDATE',
                    payload: {
                        selectedPostStyle: Blocks[userChoice__block.selectedBlock].choicesI.selectedPostStyle,
                    }
                }
            );
            dispatchUserChoice__blockLayout(
                {
                    type: 'UPDATE',
                    payload: {
                        selectedBlockLayout: Blocks[userChoice__block.selectedBlock].choicesI.selectedBlockLayout,
                    }
                }
            );
            
            
        }
    }, [userChoice__block]);

    const createCopyWithChanges = (obj1, obj2) => {
        var copyObj = JSON.parse(JSON.stringify(obj1));

        for(var key in obj2){
            copyObj.specs[key] = obj2[key];
        }
        //console.log('copyObj', copyObj);

        return copyObj;
    }

    return (
        <OptionsContext.Provider
            value={
                {
                    options__blockLayout,
                    dispatchOptions__blockLayout,
                    options__postStyle,
                    dispatchOptions__postStyle,
                    userChoice__blockLayout,
                    dispatchUserChoice__blockLayout,
                    userChoice__postStyle,
                    dispatchUserChoice__postStyle,
                    userChoice__block,
                    dispatchUserChoice__block,
                    postsJSON,
                    dispatchPostsJSON
                }
            }
        >
            {props.children}
        </OptionsContext.Provider>

    )
}

export default OptionsContextProvider