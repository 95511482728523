import React from 'react'
import BlockPreview from './../Blocks/BlockPreview'
import GetCodeBtn from './../getCode/GetCodeBtn'
import EditBlockBtn from './EditBlockBtn'
const Block = ({dataJSON, blockI}) => {
    const style = dataJSON.blockLayout.maxWidth? {maxWidth: dataJSON.blockLayout.maxWidth + 'px'} : {maxWidth: '1100px'};
    const [blockTemplate, setBlockTemplate] = React.useState('');
    const getBlockTemp = (bt) => {
        setBlockTemplate(bt);
    }
    return (
        <div className='Premade__BlockCont'>
            <div className='Premade__Block' style={style}>
                <BlockPreview dataJSON={dataJSON} getBlockTemp={getBlockTemp} />
            </div>
            <div className='Premade__controls'>
                <GetCodeBtn blockTemplate={blockTemplate} classes='Premade__btn' />
                <EditBlockBtn dataJSON={dataJSON} blockI={blockI} classes='Premade__btn' />
            </div>
        </div>
    )
}

export default Block