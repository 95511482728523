var arrowLeftIconSlider = '<svg class="slider__arrow__icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve"><g><path d="M73.7,100c1.2,0,2.3-0.4,3.2-1.3c1.8-1.8,1.8-4.6,0-6.4L34.5,50L76.9,7.7c1.8-1.8,1.8-4.6,0-6.4c-1.8-1.8-4.6-1.8-6.4,0L21.8,50l48.7,48.7C71.4,99.6,72.5,100,73.7,100z"/></g></svg>';
var arrowRightIconSlider = '<svg class="slider__arrow__icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve"><g><path d="M27.2,100c-1.2,0-2.3-0.4-3.2-1.3c-1.8-1.8-1.8-4.6,0-6.4L66.4,50L24.1,7.7c-1.8-1.8-1.8-4.6,0-6.4c1.8-1.8,4.6-1.8,6.4,0L79.1,50L30.4,98.7C29.6,99.6,28.4,100,27.2,100z"/></g></svg>';

var slider__slidesToShow = {
	slider1: 1,
	slider2: 2,
	slider3: 3,
	slider4: 4,
	slider5: 5,
	slider6: 6,
};
var sliderProps__global = {
	prevArrow: "<div class='slider__arrow slider__arrow_left transition'>" + arrowLeftIconSlider + "</div>",
	nextArrow: "<div class='slider__arrow slider__arrow_right transition'>" + arrowRightIconSlider + "</div>",
	arrowLeftIcon: arrowLeftIconSlider,
	arrowRightIcon: arrowRightIconSlider,
	touchThreshold:100,
}
var slider__responsive = {
	slider1:[],
	slider2:[
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 1,
			}
	    }
	],
	slider3:[
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 2,
			}
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 1,
			}
		}
	],
	slider4:[
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 2,
			}
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 1,
			}
		}
	],
	slider5:[
		{
			breakpoint: 1200,
			settings: {
				slidesToShow: 4,
			}
		},
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 3,
			}
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 1,
			}
		}
	],
	slider6:[
		{
			breakpoint: 1200,
			settings: {
				slidesToShow: 4,
			}
		},
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 3,
			}
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 1,
			}
		}
	],
}
if (typeof exports !== 'undefined') {
	exports.slider__slidesToShow = slider__slidesToShow;
	exports.sliderProps__global = sliderProps__global;
	exports.slider__responsive = slider__responsive;
}
