import React, {useEffect} from 'react'
import BlockDefinitions from '../BlockDefinitions'
import SelectWithImage from './utils/optionsHandlers/SelectWithImage/SelectWithImage'
import SelectRange from './utils/optionsHandlers/SelectRange/SelectRange'
import TrueFalse from './utils/optionsHandlers/TrueFalse'
import Classes from './utils/optionsHandlers/Classes'
import {OptionsContext} from '../../contexts/OptionsContext'
import {extractValue, extractSpecs} from '../../utils/func'
import GetCodeBtn from '../getCode/GetCodeBtn'
import AdminOptions from './AdminOptions/AdminOptions'

const BlockOptions = (props) => {
    
    const {
            options__blockLayout,
            dispatchOptions__blockLayout,
            options__postStyle,
            dispatchOptions__postStyle,
            userChoice__blockLayout,
            dispatchUserChoice__blockLayout,
            userChoice__postStyle,
            dispatchUserChoice__postStyle,
            setUserChoice__postStyle
            } = React.useContext(OptionsContext);


    const {BlockLayouts, BlockPostStyles} = BlockDefinitions;
    

    const renderOptions = (allOptions, obj, dispatch, $i = 0) => {
        var renderedOptions = [];
        for(var i = 0; i < allOptions.length; i++){
            var key = allOptions[i].whereToSave.join();
            var value = extractValue(allOptions[i].whereToSave, obj, $i);
            switch(allOptions[i].type){
                case 'range':
                    renderedOptions.push(
                        <SelectRange
                            key={key}
                            info={allOptions[i]}
                            obj={obj}
                            value={value}
                            $i={$i}
                            dispatch={dispatch}
                        />);
                    break;
                case 'TrueFalse':
                    renderedOptions.push(
                        <TrueFalse
                            key={key}
                            info={allOptions[i]}
                            obj={obj}
                            value={value}
                            $i={$i}
                            dispatch={dispatch}
                        />
                    );
                    break;
                
                case 'classes':
                        renderedOptions.push(
                            <Classes
                                key={key}
                                info={allOptions[i]}
                                obj={obj}
                                value={value}
                                $i={$i}
                                dispatch={dispatch}
                            />
                        )
                    break;

                default:
                    return('no available option component for this type.');
            }
        }

        return (
            <>
                {renderedOptions}
            </>
        )
    }
    

    const renderPostStyleOptions = () => {
        var postStyle = options__postStyle.postStyle;
        var theRes = [];

        var nOp = options__blockLayout.blockLayout.specs.editEachPostIndividually? postStyle.length : 1;
        for(var i = 0; i < nOp; i++){
            theRes.push(<div key={i} className='postOptions'>
                {
                    (nOp !== 1)?
                        <div className='optionSubtitle'> 
                            post #{i+1}
                        </div>

                    : ''
                }
                <SelectWithImage
                    name='selectedPostStyle'
                    items={BlockPostStyles}
                    whereToSave={['selectedPostStyle', '$i']}
                    obj={userChoice__postStyle}
                    setObj={setUserChoice__postStyle}
                    $i={i}
                    value={userChoice__postStyle[i]}
                    dispatch={dispatchUserChoice__postStyle}

                />
                {renderOptions(postStyle[i].options, options__postStyle, dispatchOptions__postStyle, i)}
                </div>)
        }

        return (
            <>
                <div className='allPostsOptions'>
                    {theRes}
                </div>
                
            </>
        )
    }

    const getAllPossibleBlocks = () => {
        let allPossibleBlocks = [];

        for(let i = 0; i < BlockLayouts.length; i++){
            for(let j = 0; j < BlockPostStyles.length; j ++){
                allPossibleBlocks.push(
                    `<div class="featuredBlockCont" >
                        <h4 class="widget__title blockTitle">
                            <span class="blockTitle__txt">
                                label:"fun" title:"fun"
                            </span>
                        </h4>
                        <div class="widget__content">
                    ` +
                    
                        JSON.stringify(
                            extractSpecs(
                                {blockLayout: BlockLayouts[i]},
                                {postStyle: [BlockPostStyles[j]]}
                            )
                        )
                    + `
                        </div>
                    </div>`
                );
            }
        }

    }
    const getCode = (e) => {
        e.preventDefault();
        
        console.log(JSON.stringify(extractSpecs(options__blockLayout, options__postStyle)));
    }

    //<button onClick={getAllPossibleBlocks}>Get All Blocks</button>

    if(options__blockLayout.blockLayout)
    return(

            <>
                <div className='BlockOptions'>

                    <div className='optionTitle'>
                        choose a layout
                    </div>
                    <SelectWithImage
                        name='selectedBlockLayout'
                        items={BlockLayouts}
                        whereToSave={['selectedBlockLayout']}
                        obj={userChoice__blockLayout}
                        dispatch={dispatchUserChoice__blockLayout}
                    />

                    {
                        renderOptions(options__blockLayout.blockLayout.options, options__blockLayout, dispatchOptions__blockLayout)
                    }
                    <div className='optionTitle postStyleOptions__title'>
                        choose posts style
                    </div>
                    
                    {
                        renderPostStyleOptions()
                    }


                    <GetCodeBtn classes='BlockOptions__GetCodeBtn w100' />
                    
                    
                    <AdminOptions />

                </div>
                
            </>
                
    )
    else
    return (
        'loading'
    )
}


export default BlockOptions

