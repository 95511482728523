import React from 'react'
import Tab from './Tab'

import {BlockBuilderController} from './../../../contexts/BlockBuilderController'
const MianTabs = () => {
    const {
        BlockOptionsFront__tabs,
        BlockOptionsFront__openedTab
    } = React.useContext(BlockBuilderController);


    return (
        <div className='MianTabs'>
            <Tab
                tab={BlockOptionsFront__tabs.CREATE}
                txt='create'
                icon='/block-builder/icons/gear.svg'
            />

            <Tab
                tab={BlockOptionsFront__tabs.PREMADE}
                txt='premade'
                icon='/block-builder/icons/dashboard.svg'
            />
        </div>
    )
}

export default MianTabs