import React, {useEffect} from 'react'
import BlockPreview from './../Blocks/BlockPreview'
import WidthResizer from './WidthResizer'
import {BlockBuilderController} from './../../contexts/BlockBuilderController'
import {OptionsContext} from './../../contexts/OptionsContext'
import {extractSpecs, minifyBlockCode}from './../../utils/func'
const BlockCrntPreview = () => {
    const {
        options__blockLayout,
        options__postStyle,
        dispatchOptions__blockLayout,
        postsJSON
    } = React.useContext(OptionsContext);
    
    const {
        currentBlockCode,
        setCurrentBlockCode,
        openCodeModal
    } = React.useContext(BlockBuilderController);
    const dataJSON = extractSpecs(options__blockLayout, options__postStyle);
    console.log('1 dataJSON.blockLayout.maxWidth', dataJSON.blockLayout.maxWidth)
    console.log('1 options__blockLayout', options__blockLayout.specs.maxWidth)
    const ggg = dataJSON.blockLayout.maxWidth? dataJSON.blockLayout.maxWidth : 900
    const [maxWidth, setMaxWidth] = React.useState(900);
    console.log('maxWidth', maxWidth)
    const getBlockTemp = (bt) => {
        setCurrentBlockCode(minifyBlockCode(bt));
    }
    
    useEffect(() => {
        console.log('dataJSON.blockLayout.maxWidth', dataJSON.blockLayout.maxWidth)
        setMaxWidth(dataJSON.blockLayout.maxWidth? dataJSON.blockLayout.maxWidth : 900)
    }, [options__blockLayout]);

    
    const BlockPreviewComp = () => {
        //dataJSON.blockLayout.maxWidth = maxWidth
        return(
        <BlockPreview dataJSON={{...dataJSON, postsJSON: postsJSON}} getBlockTemp={getBlockTemp} />
        )
    }
    
    return(
        <>
        <div className='BlockCrntPreview'>
            {BlockPreviewComp()}
            
        </div>
        <WidthResizer value={maxWidth} setValue={setMaxWidth} />
        </>
    )
}

export default BlockCrntPreview