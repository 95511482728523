import React from 'react'
import {BlockBuilderController} from './../../contexts/BlockBuilderController'
import {OptionsContext} from './../../contexts/OptionsContext'
import {minifyBlockCode} from './../../utils/func'
const GetCodeBtn = ({classes, blockTemplate}) => {
    
    const {
        options__blockLayout,
        options__postStyle
    } = React.useContext(OptionsContext);
    const {
        currentBlockCode,
        setCurrentBlockCode,
        openCodeModal
    } = React.useContext(BlockBuilderController);

    const getCode = () => {
        //console.log(JSON.stringify(extractSpecs(options__blockLayout, options__postStyle)));
        if(blockTemplate)
            setCurrentBlockCode(minifyBlockCode(blockTemplate));
        openCodeModal();
    }

    return (
        <button
            className={'btn btnA ' + (classes? classes:'')}
            onClick={getCode}
        >
            Get Code
        </button>
    )
}
export default GetCodeBtn