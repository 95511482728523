
import React, {useContext, useEffect, useState, useMemo} from 'react'
import ReactHtmlParser from 'react-html-parser'
import resBGimg from '../../../../src/HTML/assets/js/resBGimg'
import {slider__slidesToShow, sliderProps__global, slider__responsive} from '../../../../src/HTML/assets/js/sliderGlobal'
import 'lazysizes'
import Slider from "react-slick";
import InlineSVG from 'svg-inline-react';
import {resFeaturedBlocks, extractValueOfAttrib, getNPosts, removeDIVwrapper} from '../../utils/func'
const PrevArrow = ({arrowLeftIcon, onClick}) => {
    return (
        <div onClick={onClick} className='slider__arrow slider__arrow_left transition'>
            <InlineSVG src={arrowLeftIcon} />
        </div>
    )
}

const NextArrow = ({arrowRightIcon, onClick}) => {
    return (
        <div onClick={onClick} className='slider__arrow slider__arrow_right transition'>
            <InlineSVG src={arrowRightIcon} />
        </div>
    )
}


const BlockToReact = ({blockHTML, dataJSON}) => {
    console.log()
    const FB = React.createRef();
    var classes = '';
    var sliderProps = extractValueOfAttrib(blockHTML, 'sliderProps');
    blockHTML = removeDIVwrapper(blockHTML);
    var featuredBlockComp = '';
    if(sliderProps){
        var classes = extractValueOfAttrib(blockHTML, 'class');
        sliderProps = JSON.parse(sliderProps.replace(/\$q\$/g, '"'));
        blockHTML =removeDIVwrapper(blockHTML);
        
        sliderProps.prevArrow = <PrevArrow arrowLeftIcon={sliderProps__global.arrowLeftIcon} />;
        sliderProps.nextArrow = <NextArrow arrowRightIcon={sliderProps__global.arrowRightIcon} />;
        sliderProps.slidesToShow = slider__slidesToShow[dataJSON.blockLayout.name];
        sliderProps.responsive = slider__responsive[dataJSON.blockLayout.name];
        featuredBlockComp = (
            <div className={classes} >
                <Slider {...sliderProps} className='asdasd'>
                    {ReactHtmlParser(blockHTML)}
                </Slider>
            </div>
        )
    }else{
        featuredBlockComp = ReactHtmlParser(blockHTML);
    }
    //componentDidUpdate
    useEffect(() => {
        if(FB.current){
            console.log('FB', FB);
            updateBlockRes()
        }
        console.log('useEffect 1')
        
    });
    
    useEffect(() => {
        updateBlockRes();
        window.addEventListener("resize", updateBlockRes);
        console.log('useEffect 2')
    }, []);


    const updateBlockRes = () => {
        resFeaturedBlocks(FB)
        resBGimg()
    }

    return(
        <div ref={FB} className='featuredBlock' >
            {featuredBlockComp}
        </div>
    );  
}

export default BlockToReact