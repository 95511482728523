import React from 'react'
import {savePropFromArr} from './../../../../utils/func'
const TrueFalse = ({info, obj, dispatch, value, $i}) => {
    const onChange = (e) => {
        dispatch({type: 'UPDATE', payload: savePropFromArr(info.whereToSave, obj, e.target.checked, $i)});
    }
    return (
        <div className='trueFalseOption blockOptions__option'>
            <label className="trueFalseOption__label">
                <input className='trueFalseOption__checkboxInput' onChange={onChange} type="checkbox" checked={value} />
                <div className="trueFalseOption__checkbox"></div>
                <span className='trueFalseOption__txt'>{info.text}</span>
            </label>
            
        </div>
    )
}

export default TrueFalse