import React, {useState, useEffect} from 'react'
import {savePropFromArr, extractValue} from './../../../../../utils/func'
import OptionsList from './OptionsList'
import Icon from '../../../../global/Icon'
const SelectRange = ({info, obj, dispatch, value}) => {
    const [isOptionsOpen, setIsOptionsOpen] = useState(false);
    const items = [];
    const selectedI = extractValue(info.whereToSave, obj);
    for(var i = info.choices[0]; i <= info.choices[1]; i++){
        items.push(<option  key={i} value={i}>{i}</option>);

    }
    const selectRang = React.useRef();
    const onChangeNum = (n) => {
        closeOptions();
        dispatch({type: 'UPDATE', payload: savePropFromArr(info.whereToSave, obj, n)});
    }
    const toggleOpenOptions = () => {
        setIsOptionsOpen(!isOptionsOpen);
    }
    const closeOptions = (e) => {
        setIsOptionsOpen(false);
    }

    useEffect(() => {
        // add when mounted
        document.addEventListener("mousedown", handleClickOut);
        // return function to be called when unmounted
        return () => {
            document.removeEventListener("mousedown", handleClickOut);
        };
    }, []);
    const handleClickOut = (e) => {
        if(!(selectRang.current.contains(e.target))){
            closeOptions();
        }
    }
    return (
        <div ref={selectRang} className='selectRange blockOptions__option'>
            <div className='optionTitle'>
                    {info.text}
            </div>
            <div className='selectRang__header' onClick={toggleOpenOptions}>
                {value}
                <Icon
                    src='/block-builder/icons/down-chevron.svg'
                    className='selectRang__header__icon'
                />
            </div>
            {
                isOptionsOpen?
                    <OptionsList
                        options={info}
                        selectedI={selectedI}
                        onChangeNum={onChangeNum}
                    />
                :
                    ''
            }
        </div>
    )
}

export default SelectRange