
class BlockOptionsContainer {
    constructor(){
        this.obj = {};
        this.arr = [];
        this.blockOptionsCount = 0;
    }

    addBlockOptions(blockOptions){
        for(let i = 0; i < blockOptions.length; i++){
            blockOptions[i].index = this.blockOptionsCount++;
            this.obj[blockOptions[i].specs.name] = blockOptions[i];
            this.arr.push(this.obj[blockOptions[i].specs.name]);
        }
        
    }

    addOptionsTo(options, elArr){
        for(let i = 0; i < elArr.length; i++){
            this.obj[elArr[i]].addOptions(options);
        }
    }

}

class Option {
    constructor (type, choices, whereToSave, initValue, text = ''){
        this.type = type;
        this.choices = choices;
        this.whereToSave = whereToSave;
        this.initValue = initValue;
        this.text = text;
    }
}
class BlockOptions {
    constructor(){
        this.specs = {
            classes: {}
        };
        this.options = [];
    }

    addOption(option){
        var specs = this.specs;
        var foundSpecs = false;
        for(let i = 0; i < option.whereToSave.length - 1; i++){
            if(option.whereToSave[i] == 'specs'){
                foundSpecs = true;
                continue;
            }
            if(!foundSpecs) continue;

            specs = specs[option.whereToSave[i]];
        }
        specs[option.whereToSave[option.whereToSave.length - 1]] = option.initValue;
        this.options.push(option);
    }

    addOptions(options){
        for(let i = 0; i < options.length; i++){
            this.addOption(options[i]);
        }
        
    }
}
class BlockLayout extends BlockOptions {

    constructor (name, minPostsNum, maxPostsNum, numOfPosts, isFullWidth = false) {
        super();
        this.type = 'BlockLayout';
        this.specs = {
            name: name,
            minPostsNum: minPostsNum,
            maxPostsNum: maxPostsNum,
            numOfPosts: numOfPosts? numOfPosts : minPostsNum,
            isFullWidth: isFullWidth,
            classes: {},
            maxWidth: 900
        }
        
        this.addOptions([
            new Option(
                'range',
                [minPostsNum, maxPostsNum],
                ['blockLayout', 'specs', 'numOfPosts'],
                this.specs.numOfPosts,
                'number of posts'
            ),
            new Option('classes',
                [
                    {text: 'no gap', class: 'layout__noGap'},
                    {text: 'extra small gap', class: 'layout__xsGap'},
                    {text: 'small gap', class: 'layout__smGap'},
                    {text: 'medium gap', class: 'layout__mdGap'},
                    {text: 'large gap', class: 'layout__lgGap'},
                ],
                ['blockLayout', 'specs', 'classes', 'gap'],
                'layout__smGap',
                'gap between posts'
            ),
            new Option('classes',
                [
                    {text: 'extra small height', class: 'layout__xsHeight'},
                    {text: 'small height', class: 'layout__smHeight'},
                    {text: 'medium height', class: 'layout__mdHeight'},
                    {text: 'large', class: 'layout__lgHeight'},
                    {text: 'extra large', class: 'layout__xlHeight'},
                ],
                ['blockLayout', 'specs', 'classes', 'height'],
                'layout__smHeight',
                'height of posts image'
            ),
            new Option('classes',
                [
                    {text: 'hide', class: ''},
                    {text: 'show on top', class: 'viewAll__top'},
                    {text: 'show on bottom', class: 'viewAll__bottom'},
                    {text: 'show on bottom center', class: 'viewAll__bottomCenter'}
                ],
                ['blockLayout', 'specs', 'classes', 'viewAll'],
                '',
                'view all link'
            )
                
        ]);
        
        

    }
    addOption(option){
        var specs = this.specs;
        for(let i = 2; i < option.whereToSave.length - 1; i++){
            specs = specs[option.whereToSave[i]];
        }
        specs[option.whereToSave[option.whereToSave.length - 1]] = option.initValue;
        this.options.push(option);
    }

    addOptions(options){
        for(let i = 0; i < options.length; i++){
            this.addOption(options[i]);
        }
        
    }
}


class BlockPostStyle extends BlockOptions{

    constructor (name, showPostLabel = true, showPostSummary = true, showPostData = true, showPostReactions = false) {
        super();
        this.specs = {
            name: name,
            showPostLabel: showPostLabel,
            showPostData: showPostData,
            showPostSummary: showPostSummary,
            showPostReactions: showPostReactions,
            postData: {
                showPostAuthor: false,
                showPostTime: true,
                showPostComments: true
            },
            classes: {}
        }
        this.type = 'BlockPostStyle';

        this.addOptions([
            new Option('TrueFalse',
                [true, false],
                ['postStyle', '$i', 'specs', 'showPostLabel'],
                this.specs.showPostLabel,
                'show post label'
            ),

            new Option('TrueFalse',
                [true, false],
                ['postStyle', '$i', 'specs', 'postData', 'showPostAuthor'],
                this.specs.postData.showPostAuthor,
                'show post author'
            ),

            new Option('TrueFalse',
                [true, false],
                ['postStyle', '$i', 'specs', 'postData', 'showPostTime'],
                this.specs.postData.showPostTime,
                'show post time'
            ),

            new Option('TrueFalse',
                [true, false],
                ['postStyle', '$i', 'specs', 'postData', 'showPostComments'],
                this.specs.postData.showPostComments,
                'show post comments count'
            ),
            new Option('TrueFalse',
                [true, false],
                ['postStyle', '$i', 'specs', 'showPostSummary'],
                this.specs.showPostSummary,
                'show post summary'
            ),
            /*new Option('TrueFalse',
                [true, false],
                ['postStyle', '$i', 'specs', 'showPostReactions'],
                this.specs.showPostReactions,
                'show post reactions'
            ),*/
            new Option('classes',
                [
                    {text: 'none', class: ''},
                    {text: 'center', class: 'postCenterContentH'},
                ],
                ['postStyle', '$i', 'specs', 'classes', 'txtAlgin'],
                '',
                'text align'
            ),
        ]);

    }
}

exports.BlockOptionsContainer = BlockOptionsContainer;
exports.Option = Option;
exports.BlockLayout = BlockLayout;
exports.BlockPostStyle = BlockPostStyle;