import React, {useEffect} from 'react'
import Option from './Option'
const OptionsList = ({options, passSelected, selectedOptionI}) => {
    
    return (
        <div className='selectWithImage__optionsList BlockOptions__scroll'>
            {
                options.map((option, i) => (
                    <Option
                        option={option}
                        key={i}
                        i={i}
                        passSelected={passSelected}
                        isSelected={i === selectedOptionI}
                    />))
            }
        </div>
    )
}

export default OptionsList