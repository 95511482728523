import React, {useEffect} from 'react'
import {BlockBuilderController} from './../../contexts/BlockBuilderController'
import ReactSVG from 'react-svg'
const codeInput = React.createRef();
const GetCodeModal = () => {
    const [closeBtnTxt, setCloseBtnTxt] = React.useState('copy');
    const {
        GetCodeModal__isOpened,
        dispatchGetCodeModal__isOpened,
        currentBlockCode
    } = React.useContext(BlockBuilderController);

    const closeModal = () => {
        dispatchGetCodeModal__isOpened({type: 'UPDATE', payload: false});
    }
    const highlight = () => {
        codeInput.current.focus();
        codeInput.current.setSelectionRange(0, codeInput.current.value.length);
        
    }

    const copy = () => {
        highlight();
        document.execCommand('copy');
        setCloseBtnTxt('copied!');
        setTimeout(
            () => {
                setCloseBtnTxt('copy');
            }
        , 2000);
    }
    if(GetCodeModal__isOpened)
    return(
        <React.Fragment>
            <div className='getCodeModal'>
                <div className='getCodeModal__close' onClick={closeModal}>
                    <ReactSVG src='/block-builder/icons/close.svg' />
                </div>
                <div className='getCodeModal__txtareaCont'>
                    <textarea
                    className='getCodeModal__txtarea'
                        ref={codeInput}
                        readOnly
                        onClick={highlight}
                        value={currentBlockCode}
                    >
                        
                    </textarea>
                </div>
                <button
                    className='getCodeModal__closeBtn blockBuilder__btn'
                    onClick={copy}
                >
                {closeBtnTxt}
                </button>
            </div>

            <div
                className='getCodeModal__overlay'
                onClick={closeModal}
            ></div>
        </React.Fragment>
    )
    else
        return ''
}

export default GetCodeModal;