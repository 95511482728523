import React from 'react'

const OptionsList = ({options, selectedI, onChangeNum}) => {

    const items = [];

    const onClick = (e) => {
        onChangeNum(e.target.getAttribute('value'));
    }
    for(var i = options.choices[0]; i <= options.choices[1]; i++){
        items.push(<div
                    onClick={onClick}
                    className={'optionsList__ele' + (i === selectedI? ' selected' : '')}
                    key={i}
                    value={i}
                >
                    {i}
                </div>);

    }
    return (
        <div className='selectRang__optionsList BlockOptions__scroll'>
            {items}
        </div>
    )
}

export default OptionsList