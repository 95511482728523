import React from 'react'

const LoadMoreBlocks = ({thereAreMore, onClick}) => {


    return (
        <div className='LoadMoreBlocks'>
            {
                thereAreMore?
                <button className='LoadMoreBlocks__btn btn btnA w100' onClick={onClick} >Load More</button>
                :
                <p>
                no more blocks
                </p>
            }
        </div>
    )
}

export default LoadMoreBlocks