import React, {useEffect} from 'react'
import {Blocks} from './../BlockDefinitions'
import Block from './Block'
import LoadMoreBlocks from './LoadMoreBlocks'
const crntBlocks = [];

const Premade = () => {
    const [numberOfBlocksToRender, setNumberOfBlocksToRender] = React.useState(2);

    if(numberOfBlocksToRender > crntBlocks.length){
        for(var i = crntBlocks.length; i < numberOfBlocksToRender && i < Blocks.length; i++){
            crntBlocks.push(<Block key={i} dataJSON={Blocks[i]} blockI={i} />);
        }
    }

    return (
        <div className='Premade'>
            <div className='Premade__Blocks'>
                {crntBlocks.map((b) => (b))}
            </div>
            <LoadMoreBlocks
                thereAreMore={Blocks.length > numberOfBlocksToRender}
                onClick={() => {setNumberOfBlocksToRender(numberOfBlocksToRender + 2)}}
            />
            
        </div>
    )
}

export default Premade