import React from 'react'

const ViewAll = ({classes}) => {

    return (
        <div class={'viewAll ' + (classes? classes : '')}>
            <a href='javascript:;' class='viewAll__link'>
                View All &gt;
            </a>
        </div>
    )
}

export default ViewAll