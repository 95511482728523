"use strict"
function subPosts(temp, posts) {
	//var containsReactions = temp.indexOf('.reaction') >= 0;
	//var remStr = ['\<\!\-\-\$\$', '\$\$\-\-\>'];
	//temp = temp.replace('featuredBlock_notLoaded', 'featuredBlock_loaded');
	temp = temp.replace(/\$r\$|\<\!\-\-rr|rr\-\-\>|href=('|")\/('|")/g, '');
	temp = temp.replace(/atag/g, 'a');
	temp = temp.replace(/data-href/g, 'href');
	//temp = temp.replace(/href=('|")\/('|")/g, '');


	temp = temp.replace(/{{posts\[.+?}}/g, function (str, i, j) {
		var loc = [];
		var crntLoc = '';
		for(var i = 0; i < str.length; i++){
			if(str[i].match(/(\{|\}|\.|\[|\])/)){
				if(crntLoc){
					loc.push(crntLoc);
					crntLoc = '';
				}
			}else{
				crntLoc+= str[i];
			}
		}
		var objHasValue = true;
		var crntObj = posts;
		for(var i = 1; i < loc.length; i++){
			if(typeof crntObj[loc[i]] !== 'undefined'){
				crntObj = crntObj[loc[i]];
			}else{
				objHasValue = false;
				break;

			}
		}

		if(typeof objHasValue !== 'undefined' || typeof objHasValue !== null )
			return crntObj;
		else
			return str;
	});
	
	/*if(containsReactions){
		for(var i = 0; i < posts.length; i++){
				temp = subPosts__handleReactions(temp, posts[i]);
			}
	}*/

	var messages_comments = (typeof messages_comments === 'undefined')? 'comments' : messages_comments;
	temp = temp.replace(/{{messages_comments}}/g, messages_comments);


	temp = subPosts__handleDrafts (temp, posts);
	
	return temp;
}

/*
function subPosts__handleReactions (temp, post) {
	var crntReactions = 'reactions_' + post.index;
	var reactionsRegex = new RegExp('<ul class="reactions[^\>]+?'+crntReactions+'(.|\\n)+?<\/ul>');
	temp = temp.replace(reactionsRegex, function (str) {
		if(!post.reactions.length) return '';
		var ReactionIndex = -1;
		str = str.replace(/<li class="reactions__element(.|\n)+?<\/li>/g, function (reaction) {
			ReactionIndex++;
			if(post.reactions.length > ReactionIndex){
				return reaction;
			}
			return '';
		});

		return str;
	});
	return temp;
}*/


function subPosts__handleDrafts (temp, posts) {
	var draftPostsI = [];

	for(var i = 0; i < posts.length; i++){
		if(posts[i].isDraft)
			draftPostsI.push(i);
	}

	if(draftPostsI.length){
		var j = 0;
		var i = -1;
		temp = temp.replace(/class=(\'|\")[^\>\'\"]*?aPost.*?(\'|\")/g, function (str) {
			i++;
			if(draftPostsI[j] === i){
				str = str.replace(/( |\'|\")aPost( |\'|\")/g, function (str2) {
					return str2.replace('aPost', 'aPost isDraft');
				});
				j++;
			}
			console.log(str);
			return str;
		});
	}

	return temp;
}


if (typeof exports !== 'undefined') {
	module.exports = subPosts;
}


