import React from 'react'
import {BlockBuilderController} from './../../../contexts/BlockBuilderController'
import Icon from './../../global/Icon';
const Tab = ({tab, txt, icon}) => {
    const {
        BlockOptionsFront__openedTab,
        switchTabs
    } = React.useContext(BlockBuilderController);
    const isSelected = (tab == BlockOptionsFront__openedTab)
    return(
        <div
            className={'Header__tabBtn tabBtn' + (isSelected? ' selected': '')}
            onClick={switchTabs}
            tab={tab}
        >
            <Icon
                src={icon}
                className='tabBtn__icon'
            />
            <span className='tabBtn__txt'> 
                {txt}
            </span>
            
        </div>
    )
}   

export default Tab;