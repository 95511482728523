import React from 'react';
import Icon from '../../../global/Icon'
const MinimizeBtn = ({isMinimized, toggleMinimize}) => {

    const renderIcon = () => {
        if(isMinimized)
            return(<Icon src='/block-builder/icons/right-arrow.svg' />);
        else
            return(<Icon src='/block-builder/icons/left-arrow.svg' />);
    }

    return (
        <div onClick={toggleMinimize} className='minimizeBtn'>
            {renderIcon()}
        </div>
    )
}

export default MinimizeBtn;