import React from 'react'
import GetJSONOptionsBtn from '../../getCode/GetJSONOptionsBtn'
import PostsForm from './PostsForm'
const AdminOpotins = () => {

    if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development')
        return ''
    

    return (
        <div className='AdminOpotins'>
            <GetJSONOptionsBtn />
            <PostsForm />
        </div>
        
    )
}

export default AdminOpotins